export default ({
  namespaced: true,
  state: () => ({
    // legalProvidersTableData: {
    //   header: [
    //     { ttl: '', info: null },
    //   ],
    //   paginationData: null,
    // },
    legalProvidersInfo: {
      total: '',
      fintechUsersCount: '',
      standartUsersCount: '',
      newUsersCount: '',
      kybRateCount: ''
    },
    legalProvidersPagination: null,
    legalProviders: []
  }),
  actions: {
    async getLegalProviders ({ commit }, payload) {
      let pageStr = payload && payload.page ? payload.page : (typeof payload == 'number') ? payload : 1;  
      await axios.get('admin/legal-provider?page=' + pageStr)
        .then(({ data }) => {
          commit('setLegalProviders', { data: data });
        })
        .catch(function (error) {
          console.dir(error);
        })
    },
    async getLegalProvidersInfo ({ commit }, payload) {
      await axios.get('admin/legal-provider/info')
        .then(({ data }) => {
          commit('setLegalProvidersInfo', { data: data });
        })
        .catch(function (error) {
          console.log(error);
        })
    },
  },
  mutations: {
    setLegalProviders (state, payload) {
      state.legalProvidersPagination = payload.data
      state.legalProviders = [];

      payload.data.data.forEach(el => {
        const isNewHtml = (el.payment_settings && el.payment_settings.payment_request_settings_changed) ? '<span class="badge  new-request-badge">New</span>' : ''
        const requestHtml = (el.request_changed_date) ? el.request_changed_date + isNewHtml : ''
        let balanceItem = (el.balance.trim().length !== 0 && el.balance) ? that.number_format(el.balance, 2, '.', ' ') : ''
        if (el.balance_currency) {
          balanceItem = balanceItem + ' ' + el.balance_currency.code;
        }
        let name = el.full_name
        const kybRate = (parseInt(el.is_kyb_rate_manual) == 1) ? el.kyb_rate_manual : el.kyb_rate 
        if (!name && typeof el.company_full_name !== 'undefined') {
          name = el.company_full_name
        }

        state.legalProviders.push({
          id: el.id,
          serial_number: el.serial_number,
          date_join: el.created_at_text,
          name: name,
          // rating: { type: 'rating', rating: el.rating, rating_color: el.rating_color },
          rating: el.rating,
          kyb_rate: kybRate,
          customer_type: el.customer_type_name,
          location: el.location,
          request_change: { type: 'request-change', html: requestHtml },
          balance: balanceItem,
          discount_code: el.discount_code,
          is_admin: el.reg_admin ? 'Admin' : 'User',
        });
      })
    },
    setLegalProvidersInfo (state, payload) {
      state.legalProvidersInfo = payload.data
    },
  }
})
