<template>
  <div class="tbl">
    <div class="tbl_header">
      <div
        v-for="(i, key) in options.header"
        :key="key"
        class="col"
        :class="i.class ? i.class : ''"
        :style="{ 'width': options.colsWidth[key] }"
      ><span>
        {{ i.ttl }}
        <Info v-if="i.info" :pos="i.info.pos" :txt="i.info.txt" />
        <span
          v-if="i.sort"
          class="sort"
          :class="{
            'sort--load': options.sort.loading,
            'sort--asc': options.sort.name === i.sort && options.sort.order === 'asc',
            'sort--desc': options.sort.name === i.sort && options.sort.order === 'desc',
          }"
          @click="sortBy(i.sort)"
        >&#9650;</span>
      </span></div>
    </div>
    <div class="tbl_body">
      <div
        v-for="(i, key) in options.body" :key="key"
        class="tbl_row"
        :style="{ 'cursor': chatAppliedOfferType ? 'pointer' : '' }"
        @click="clickRow(key)"
      >
        <div
          v-for="(el, el_key) in i"
          :key="`w_${el_key}`"
          class="col"
          :style="{ 'width': getCellWidth(el, el_key) }"
        >
          <div v-if="isType(el, 'edit')" class="box_btns">
            <span @click="$emit('edit', i[0])" class="btn btn-edit"><Pen class="img"/></span>
          </div>
          <div v-else-if="getType(el) === 'object' && el.type === 'edit-delete-actions'" class="box_btns">
            <span @click="editItemItem(el.id)" class="btn btn-edit"><Pen class="img"/></span>
            <span @click="deleteItem(el.id)" class="btn btn-del"><Close class="img"/></span>
          </div>
          <div v-else-if="isType(el, 'user-details')" class="link">
            <a href="#" @click.prevent="gotoUserDetails(el.user_id)">{{ el.full_name }}</a>
          </div>
          <div v-else-if="getType(el) === 'object' && el.type === 'user-details-delete-actions'" class="box_btns">
            <span @click="gotoUserDetails(el.userId)" class="btn btn-edit"><Pen class="img"/></span>
            <span @click="deleteItem(el.id)" class="btn btn-del"><Close class="img"/></span>
          </div>
          <div v-else-if="getType(el) === 'object' && el.type === 'request-change'" v-html="el.html"></div>
          <div v-else-if="getType(el) === 'object' && el.type === 'apply-btn'" class="btn-apply" v-html="el.html" @click="apply(el.id, el.applyType)"></div>
          <div v-else-if="getType(el) === 'object' && el.type === 'delete-btn'" class="btn-delete" v-html="el.html" @click="deleteItem(el.id)"></div>
          <div v-else-if="getType(el) === 'object' && el.type === 'basic'" class="btn" v-html="el.html"></div>
          <div v-else-if="getType(el) === 'object' && el.type === 'deadline'" class="ddln">
            Deadline: <span class="ddln_val">{{ el.days }}</span> <span class="ddln_desc">days</span> <span class="ddln_val">{{ el.hours }}</span> <span class="ddln_desc">hours</span>
          </div>
          <template v-else-if="getType(el) === 'object' && el.type === 'deadline_legal'">
            <div class="ddln">
              Deadline: <span class="ddln_val">{{ el.days }}</span> <span class="ddln_desc">days</span> <span class="ddln_val">{{ el.hours }}</span> <span class="ddln_desc">hours</span>
            </div>
            <div class="del ml-3">
              <div class="btn btn_close" @click="apply(el.id, el.applyType)"><Close /></div>
            </div>
          </template>
          <div v-else-if="getType(el) === 'object' && el.type === 'deadline_lite'" class="ddln">
            <span class="ddln_val">{{ el.days }}</span> <span class="ddln_desc">days</span> <span class="ddln_val">{{ el.hours }}</span> <span class="ddln_desc">hours</span>
          </div>
          <span v-else-if="getType(el) === 'object' && el.type === 'info'" >
            {{ el.html }}
            <Info :pos="el.info.pos" :txt="el.info.txt" />
          </span>
          <div v-else-if="getType(el) === 'object' && el.type === 'rating'" class="rating-color" :style="{'background-color': el.rating_color, 'display': 'flex', 'align-items': 'center', 'justify-content': 'center'}">{{ el.rating }}
          </div>
          <div
            v-else-if="getType(el) === 'object' && el.type === 'rating_btn'"
            class="rating_btn"
            @click="$emit('rating-modal', i[0])"
          >
            <div
              v-if="el.rating || el.rating_color"
              class="rating_btn--data"
              :style="{ 'background-color': el.rating_color }"
              v-text="el.rating"
            />
            <div v-else class="btn rating_btn--empty"><Pen class="img"/></div>
          </div>
          <span v-else-if="getType(el) === 'object' && el.type === 'del'" class="del">
            <div class="btn btn_close"><Close /></div>
          </span>
          <span v-else-if="getType(el) === 'object' && el.type === 'notification'" class="notification">
            <span @click="showNotificationDetails(el.notificationType, el.notification)">{{ el.text }}</span>
          </span>
          <span v-else-if="getType(el) === 'object' && el.type === 'chat-request'" class="link">
            <span @click="showChatRequests(el.userId)">{{ el.count }}</span>
          </span>
          <span v-else-if="getType(el) === 'object' && el.type === 'show-chat'">
            <span @click="showChat(el.chatId)" class="btn btn-del"><Chat class="img"/></span>
          </span>
          <template v-else-if="getType(el) === 'object' && el.type === 'deadline_info'">
            <div class="ddln">
              <span class="ddln_val">{{ el.days }}</span> <span class="ddln_desc">days</span> <span class="ddln_val">{{ el.hours }}</span> <span class="ddln_desc">hours</span>
            </div>
          </template>
          <template v-else-if="getType(el) === 'object' && el.type === 'cancel'">
            <div class="del ml-3">
              <div class="btn btn_close" @click="cancel(el.id, el.applyType)"><Close /></div>
            </div>
          </template>
          <span v-else-if="getType(el) === 'object' && el.type === 'more'" class="more">
            <div class="btn btn_more" @click="openMoreModal(i[0])"><More /></div>
          </span>
          <span
            v-else-if="getType(el) === 'object' && el.type === 'v_link'"
            class="link"
            v-html="el.html"
            @click="$router.push(el.link)"
          />
          <div @click="showUserBusinessOffers(el.id)" class="btn show-user-business-offers" v-else-if="getType(el) === 'object' && el.type === 'show-user-business-offers'" v-html="el.html"></div>
          <span v-else-if="getType(el) === 'object' && el.type === 'hidden-id'" style="display: none">
            {{ el.id }}
          </span>
          <span v-else >
            <truncate v-if="el" clamp="..." :length="30" less="Show Less" :text="el"></truncate>
          </span>
        </div>
      </div>
      <div v-if="options.footer && options.footer.type === 'Total Price' " class="tbl_row tbl_row-footer">
        <div class="tp">Total Price: <span class="val">{{ options.footer.val }}</span></div>
      </div>
    </div>
    <div class="tbl_footer">
      <pagination
        v-if="options.paginationData"
        :data="options.paginationData"
        :limit="2"
        @pagination-change-page="paginationChangePage"
      />
    </div>
  </div>
</template>

<script>
import Pagination from 'laravel-vue-pagination'
import Info from '@/components/elem/Info.vue'
import Pen from '@/components/img/Pen.vue'
import Close from '@/components/img/Close.vue'
import Chat from '@/components/img/Chat.vue'
import More from '@/components/img/More'
import { mapMutations } from 'vuex';
import truncate from 'vue-truncate-collapsed'

export default {
  components: {
    Pagination,
    Info,
    Close,
    Pen,
    More,
    truncate, 
    Chat,
  },
  props: {
    options: {
      type: Object,
      required: true
    },
    chatAppliedOfferType: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    ...mapMutations({
      setChatOffer: 'SET_CHAT_OFFER',
    }),
    isType(obj, type) {
      return (this.getType(obj) === 'object' && obj.type === type)
    },
    getCellWidth(data, key) {
      let w = this.options.colsWidth[key]

      if (this.getType(data) === 'object' && data.type === 'deadline') {
        w = `calc(${this.options.colsWidth[key]} + ${this.options.colsWidth[key + 1]})`
      }

      if (this.getType(data) === 'object' && data.type === 'deadline_legal') {
        w = `calc(${this.options.colsWidth[key]} + ${this.options.colsWidth[key + 1]})`
      }

      return w
    },
    apply (id, applyType) {
      this.$emit('apply', {
        id,
        applyType,
      })
    },
    cancel (id, applyType) {
      this.$emit('cancel', {
        id,
        applyType,
      })
    },
    deleteItem (id) {
      this.$emit('deleteItem', {
        id: id,
      })
    },
    editItemItem (id) {
      this.$emit('editItem', {
        id: id,
      })
    },
    openMoreModal (id) {
      this.$emit('more-modal', id)
    },
    paginationChangePage (page) {
      if (this.options.comingSoon) return

      this.$emit('change-page', page)
      this.$emit('scroll-top', this.$refs.tbl)
    },
    showNotificationDetails (type, notification) {
      if (type === 'update-profile') {
        this.$store.dispatch('notifications/viewUpdateProfileFields', notification)
      }
      if (type === 'update-business-information') {
        this.$store.dispatch('notifications/viewUpdatedBusinessInformationFields', notification)
      }
      if (type === 'update-logo') {
        this.$store.dispatch('notifications/viewUpdatedLogo', notification)
      }
      if (type === 'update-request') {
        this.$store.dispatch('notifications/updateRequest', notification)
      }
      if (type === 'apply-offer') {
        this.$store.dispatch('notifications/applyOffer', notification)
      }
      if (type === 'cancel-offer') {
        this.$store.dispatch('notifications/cancelOffer', notification)
      }
      if (type === 'update-compliance') {
        this.$store.dispatch('notifications/updateCompliance', notification)
      }
      if (type === 'create-service-chat') {
        this.$router.push({ name: "Chat", params: { chatId: notification.notification.data.chat_id } })
      }
      if (type === 'create-offer-chat') {
        this.$router.push({ name: "Chat", params: { chatId: notification.notification.data.chat_id } })
      }
      if (type === 'create-company' || type === 'create-incorporation-company') {
        this.$router.push({ name: "CustomerDetail", params: {
          id: notification.notification.data.user_id,
          company_id: notification.notification.data.company_id
        }})
      }
    },
    sortBy (name) {
      if (this.options.sort.loading) return
      this.$emit('sort', {
        obj: this.options.objName,
        sort: name
      })
    },
    showUserBusinessOffers (id) {
      this.$emit('show-user-bos-offers', {
        id: id,
      })
    },
    gotoUserDetails (id) { 
      this.$router.push({ name: "CustomerDetail", params: { id: id }})
    },
    clickRow (row) {
      if (this.chatAppliedOfferType.length > 0) {
        for (var prop in this.options.body[row]) {
            if (Object.prototype.hasOwnProperty.call(this.options.body[row], prop)) {
                const p = this.options.body[row][prop]
                if (p.type && p.type == 'hidden-id') {
                  this.setChatOffer({
                    id: p.id,
                    type: this.chatAppliedOfferType,
                    time: +Date.now()
                  })
                  this.$emit('close',)
                }
            }
        }
      }
    },
    showChatRequests(userId) {
      this.$router.push({ name: "Requests", params: { id: userId }})
    },
    showChat (chatId) {
      this.$router.push({ name: "Chat", params: { chatId: chatId } })
    }
  },
  watch: {
    'myProfileForm.registration_country': function (countryId) {
      this.setCountryName(countryId)
    },
  }
}
</script>

<style lang="scss" scoped>
.tbl {
  &_header {
    .col {
      // padding: 0.75rem 2px;
      padding: .5rem 2px;
      font-size: 14px;
      min-height: 3.25rem;
      display: flex;
      align-items: center;
      justify-content: center;

      &.gradient_btn {
        padding: 6px;
        position: relative;

        $margin: 4px;
        &::before {
          content: '';
          position: absolute;
          top: $margin; right: $margin; bottom: $margin; left: $margin;
          // background: linear-gradient(113.24deg, #13B497 16%, #05737A 106.71%);
          background: linear-gradient(113.24deg, #13B497 16%, #3baab2 106.71%);
          filter: blur(1px);
          border-radius: 14px;
        }

        span {
          position: relative;
          width: 100%;
          min-height: 2.75rem;
          padding: .25rem;
          display: flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(-5deg, #13B497 -100%, #05737A 100%);
          border-radius: 14px;
        }
      }

      .sort {
        font-size: 22px;
        line-height: 0.3;
        cursor: pointer;
        padding: 0 0.25rem;
        display: inline-flex;
        align-items: baseline;

        &--asc {
          transform: rotate(180deg);
          color: #1C31F0;
        }

        &--desc {
          color: #1C31F0;
        }

        &--load {
          color: rgba(128, 128, 128, 0.4);
          cursor: default;
        }
      }
    }
  }

  &_body {
    .tbl_row {
      border-bottom: 1px solid #323940;

      &:last-child {
        border: none;
      }

      .col {
        // position: relative;
        text-align: center;
        color: rgba(255, 255, 255, 0.8);
        padding: 6px 4px;
        min-height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          word-break: break-word;
        }

        .box {
          &_btns {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            justify-content: space-evenly;
          }
        }

        .btn {
          width: calc(100% - 1.5rem);
          color: #1C31F0;
          padding: .5rem .75rem;
          // background: rgba(19, 180, 151, 0.1);
          border-radius: 6px;

          &-apply{
            width: initial;
            color: #fff;
            padding: .5rem .75rem;
            border-radius: 6px;
            background: #1C31F0;
            cursor: pointer;
          }

          &-delete{
            width: initial;
            color: #fff;
            padding: .5rem .75rem;
            border-radius: 6px;
            background: rgb(166, 1, 1);
            cursor: pointer;
          }

          &.info {
            width: 1rem;
            padding: 0;
            background: transparent;
          }

          &-edit,
          &-del {
            width: 2.75rem;
            height: 2.75rem;
            padding: .75rem;
            margin: .25rem;
            display: flex;
            align-items: center;
            justify-content: center;

            svg.img {
              width: 1rem;
              height: 1rem;
            }
          }

          &-del {
            ::v-deep svg.img path {
              fill: red;
            }
          }

          &_more {
            width: 2.75rem;
            height: 2.75rem;

            svg {
              width: 100%;
              height: 100%;
            }
          }
        }

        .show-user-business-offers {
          width: 100px;
        }

        .ddln {
          // position: absolute;
          // top: 50%;
          // right: 10%;
          // transform: translateY(-50%);
          width: calc(100% - 1.5rem);
          background: #393b41;
          border-radius: 6px;

          &_val {
            font-size: 24px;
            color: #1C31F0;
          }

          &_desc {
            font-size: 14px;
          }
        }

        .del {
          .btn_close {
            width: 2rem;
            height: 2rem;
            padding: .25rem;
            background-color: #B03636;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              width: 45%;
              height: 45%;
            }
          }
        }
      }

      &:hover {
        background-color: #3D444B;

        &:last-child {
          border-bottom-left-radius: 14px;
          border-bottom-right-radius: 14px;
        }
      }

      &-footer {
        justify-content: flex-start;
        padding-left: 20vw;

        .tp {
          font-size: 1.25rem;
          padding: .5rem 2rem;
          margin: .5rem;
          display: flex;
          align-items: baseline;
          justify-content: center;
          background-color: #272E35;
          border-radius: 6px;

          .val {
            color: #1C31F0;
            margin-left: 1.5rem;
          }
        }
      }
    }
  }

  &_footer {
    ::v-deep .pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      // background: rgba(19, 180, 151, 0.1);
      border-radius: 14px;

      .page-item {
        margin: .75rem .5rem;

        a {
          width: 2.25rem;
          max-width: 2.25rem;
          height: 2.25rem;
          max-height: 2.25rem;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          padding: .5rem;
          font-size: 1.25rem;
          color: #1C31F0;
          text-decoration: none;
          text-transform: uppercase;
          background-color: #2F363D;
          border-radius: 50%;

          .sr-only {
            display: none;
          }
        }

        &.active {
          a {
            color: #ffffff;
            background-color: #1C31F0;
            font-weight: 800;
          }
        }
      }
    }
  }
}

.request-table .tbl_header .col {
  font-size: 9px !important;
}

.request-table .tbl_body .tbl_row .col {
  font-size: 9px;
}

.rating-color {
    list-style: none;
    width: 25px;
    height: 25px;
    float: left;
    margin-right: 5px;
    margin-bottom: 5px;
    position: relative;
    cursor: pointer;
    border-radius: 2rem;
    padding-top: 3px;
}

.rating_btn {
  display: flex;
  align-items: center;
  justify-content: center;

  > * {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--data {
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 2rem;
    line-height: 1.2;
    cursor: pointer;
  }

  &--empty {
    width: 2.75rem;
    height: 2.75rem;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.notification {
    display: flex !important;
    width: 100%;
    margin: 1.5rem 0;
}

.notification span {
  justify-content: flex-start;
  padding: 0.25rem 0.75rem;
  color: white;
  font-size: 14px;
  background: linear-gradient(-5deg, #13B497 -100%, #05737A 100%);
  box-shadow: 0px 12px 23px rgb(62 73 84 / 4%);
  border-radius: 14px;
  cursor: pointer;
}

.link {
  text-decoration: underline;
  color: #1C31F0;
  cursor: pointer;
}

</style>