<template>
  <overlay-scrollbars class="page page-offer shapes-bg">
    <div class="section-title-wrap">
      <h2 class="page-details__title section-title">
        <span class="bc-first">Offer</span>
      </h2>
    </div>
    <div class="box box_selectedAcc box-bg-shapes">
      <div class="box-header">
        <div class="box-header__left">
          <div class="box-title">Bank account</div>
          <div @click="createBankAccount" class="btn-gradient create-account-btn">Create</div>
        </div>
        <div class="box-header__right">
          <div class="top-left-actions-btns">
            <div class="btn-gradient" :class="{ 'btn--active': showProvidersAccs }" @click="toggleAccs">
              Providers
            </div>
            <!-- v-if="user.is_super_admin" -->
            <select v-model="selectedRisk" class="select-risk">
              <option v-for="(filterRisk, index) in filterRisks" :value="filterRisk.id" :key="filterRisk.id">{{
                filterRisk.name }}</option>
            </select>
            <div class="btn-excel" @click="downloadExcel('bank')">
              Download to Excel
            </div>
          </div>
        </div>
      </div>
      <div class="box-body">
        <div class="main-table offers-table">
          <DataTable :value="bankAccounts" dataKey="id" :loading="loading">
            <Column field="serial_number" header="#"></Column>
            <Column field="name" header="Name" sortable></Column>
            <Column field="type" header="Type of Account" sortable></Column>
            <Column field="oif" header="Open/Integration fee min" sortable></Column>
            <Column field="oif_max" header="Open/Integration fee max" sortable></Column>
            <Column field="inc_fee" header="Incoming fee" sortable></Column>
            <Column field="outg_fee" header="Outgoing fee" sortable></Column>
            <Column field="onboarding_time" header="Onboarding time" sortable></Column>
            <Column header="">
              <template #body="{ data }">
                <div class="col-dark">
                  <div class="table-col-icon" @click="editBankOffer(data.id)">
                    <EditIcon fill="var(--c_black)" />
                  </div>
                  <div class="table-col-icon" @click="deleteBankOffer(data.id)">
                    <TableRemoveItemIcon style="width: 30px; height: 30px" />
                  </div>
                </div>
              </template>
            </Column>
          </DataTable>
          <pagination :data="bankAccountsPaginationData" @pagination-change-page="getBankOffers">
            <span slot="prev-nav">&lt;</span>
            <span slot="next-nav">&gt;</span>
          </pagination>
        </div>
      </div>

    </div>

    <div class="box box_merchantOffers box-bg-shapes">
      <div class="box-header">
        <div class="box-header__left">
          <div class="box-title">Merchant account</div>
          <div @click="createMerchantAccount" class="btn-gradient create-account-btn">Create</div>
        </div>
        <div class="box-header__right">
          <div class="top-left-actions-btns">
            <!-- <div class="btn" :class="{ 'btn--active': showMerchantAcc }" @click="toggleMerchantAcc">
              Providers
            </div> -->
            <div class="btn-excel" @click="downloadExcel('merchant')">
              Download to Excel
            </div>
          </div>
        </div>
      </div>
      <div class="box-body">
        <div class="main-table offers-table"> 
          <DataTable :value="merchantAccounts" dataKey="id" :loading="loading">
            <Column field="serial_number" header="#"></Column>
            <Column field="name" header="Name" sortable></Column>
            <Column field="type" header="Type of Account" sortable></Column>
            <Column field="oif" header="Open/Integration fee min" sortable></Column>
            <Column field="oif_max" header="Open/Integration fee max" sortable></Column>
            <Column field="inc_fee" header="Incoming fee" sortable></Column>
            <Column field="outg_fee" header="Outgoing fee" sortable></Column>
            <Column field="onboarding_time" header="Onboarding time" sortable></Column>
            <Column header="">
              <template #body="{ data }">
                <div class="col-dark">
                  <div class="table-col-icon" @click="editMerchantOffer(data.id)">
                    <EditIcon fill="var(--c_black)" />
                  </div>
                  <div class="table-col-icon" @click="deleteMerchantOffer(data.id)">
                    <TableRemoveItemIcon style="width: 30px; height: 30px" />
                  </div>
                </div>
              </template>
            </Column>
          </DataTable>
          <pagination :data="merchantPaginationData" @pagination-change-page="getMerchantOffers">
            <span slot="prev-nav">&lt;</span>
            <span slot="next-nav">&gt;</span>
          </pagination>
        </div>
      </div>
    </div>

    <div class="box box_merchantOffers box-bg-shapes">
      <div class="box-header">
        <div class="box-header__left">
          <div class="box-title">E-Wallet</div>
          <div @click="createEWallet" class="btn-gradient create-account-btn">Create</div>
        </div>
        <div class="box-header__right">
          <div class="top-left-actions-btns">
            <!-- <div class="btn" :class="{ 'btn--active': showMerchantAcc }" @click="toggleMerchantAcc">
              Providers
            </div> -->
            <div class="btn-excel" @click="downloadExcel('merchant')">
              Download to Excel
            </div>
          </div>
        </div>
      </div>
      <div class="box-body">
        <div class="main-table offers-table"> 
          <DataTable :value="eWallets" dataKey="id" :loading="loading">
            <Column field="serial_number" header="#"></Column>
            <Column field="name" header="Name" sortable></Column>
            <Column field="type" header="Type of Account" sortable></Column>
            <Column field="oif" header="Open/Integration fee min" sortable></Column>
            <Column field="oif_max" header="Open/Integration fee max" sortable></Column>
            <Column field="inc_fee" header="Incoming fee" sortable></Column>
            <Column field="outg_fee" header="Outgoing fee" sortable></Column>
            <Column field="onboarding_time" header="Onboarding time" sortable></Column>
            <Column header="">
              <template #body="{ data }">
                <div class="col-dark">
                  <div class="table-col-icon" @click="editEWallet(data.id)">
                    <EditIcon fill="var(--c_black)" />
                  </div>
                  <div class="table-col-icon" @click="deleteEWallet(data.id)">
                    <TableRemoveItemIcon style="width: 30px; height: 30px" />
                  </div>
                </div>
              </template>
            </Column>
          </DataTable>
          <pagination :data="eWalletsPaginationData" @pagination-change-page="getEWallets">
            <span slot="prev-nav">&lt;</span>
            <span slot="next-nav">&gt;</span>
          </pagination>
        </div>
      </div>
    </div>

  </overlay-scrollbars>
</template>

<script>
import { mapState } from 'vuex';
import EditIcon from "@/components/icons/EditIcon.vue";
import TableRemoveItemIcon from '@/components/icons/TableRemoveItemIcon.vue';

export default {
  components: {
    EditIcon,
    TableRemoveItemIcon
  },
  data: () => ({
    showProvidersAccs: false,
    filterRisks: [],
    selectedRisk: null,
    bankAccounts: [],
    bankAccountsPaginationData: {},
    merchantAccounts: [],
    merchantPaginationData: {},
    eWallets: [],
    eWalletsPaginationData: {},
    loading: false
  }),
  created() {
    this.$store.dispatch('setUser')
    this.getBankOffers()
    this.getMerchantOffers()
    this.getEWallets()
    this.getRisks()
    this.risk = (this.$store.state.user?.risk) ? this.$store.state.user.risk : '';
    this.paid = (this.$store.state.user?.is_paid == 1) ? 'Paid' : 'Unpaid';
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
  },
  methods: {
    getFinName(el) {
      return !el.provider_id && !el.is_legal_company ? el.financial_institution_name : el.financial_institution_name + ' (Provided by Legal company)' 
    },
    // getOif(el) {
    //   return el.oif_min_max && el.open_integration_fee_max
    //     ? this.number_format(el.open_integration_fee, 2, '.', ' ') + ' - ' + this.number_format(el.open_integration_fee_max, 2, '.', ' ') + ' €'
    //     : this.number_format(el.open_integration_fee, 2, '.', ' ') + ' €'
    // },
    toggleAccs() {
      this.showProvidersAccs = !this.showProvidersAccs
      this.getBankOffers(1)
      this.getMerchantOffers(1)
    },
    getBankOffers(page = 1) {
      const basePath = 'admin/offers/bank-accounts?page=' + page
      const selectedRiskParam = this.selectedRisk ? this.selectedRisk : ''
      const sortParams = '&providers=' + this.showProvidersAccs + '&risk=' + selectedRiskParam

      axios.get(basePath + sortParams)
        .then(res => {
          this.bankAccountsPaginationData = res.data
          this.bankAccounts = []

          res.data.data.forEach(el => {
            this.bankAccounts.push({
              id: el.id,
              serial_number: el.serial_number,
              name: this.getFinName(el),
              type: el.account_type,
              oif: (el.open_integration_fee) ? el.open_integration_fee + ' €' : null,
              oif_max: (el.open_integration_fee_max) ? el.open_integration_fee_max + ' €' : null,
              inc_fee: el.incoming_fee,
              outg_fee: el.outgoing_fee,
              onboarding_time: el.onboarding_time_text,
            })
          })

          // if (this.bankAcc.sort.name) this.bankAcc.loading = false
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    getMerchantOffers(page = 1) {
      const basePath = 'admin/offers/merchant-accounts?page=' + page
      const selectedRiskParam = this.selectedRisk ? this.selectedRisk : ''
      const sortParams = '&providers=' + this.showProvidersAccs + '&risk=' + selectedRiskParam

      axios.get(basePath + sortParams)
        .then(res => {
          this.merchantAccounts = []
          this.merchantPaginationData = res.data;
          
          res.data.data.forEach(el => {
            this.merchantAccounts.push({
              id: el.id,
              serial_number: el.serial_number,
              name: this.getFinName(el),
              type: el.account_type,
              oif: (el.open_integration_fee) ? el.open_integration_fee + ' €' : null,
              oif_max: (el.open_integration_fee_max) ? el.open_integration_fee_max + ' €' : null,
              inc_fee: el.incoming_fee,
              outg_fee: el.outgoing_fee,
              onboarding_time: el.onboarding_time_text,
            })
          })

          // if (this.merchantAcc.sort.name) this.merchantAcc.sort.loading = false
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    getEWallets(page = 1) {
      const basePath = 'admin/offers/e-wallets?page=' + page
      const selectedRiskParam = this.selectedRisk ? this.selectedRisk : ''
      const sortParams = '&providers=' + this.showProvidersAccs + '&risk=' + selectedRiskParam

      axios.get(basePath + sortParams)
        .then(res => {
          this.eWallets = []
          this.eWalletsPaginationData = res.data;
          
          res.data.data.forEach(el => {
            this.eWallets.push({
              id: el.id,
              serial_number: el.serial_number,
              name: this.getFinName(el),
              type: el.account_type,
              oif: (el.open_integration_fee) ? el.open_integration_fee + ' €' : null,
              oif_max: (el.open_integration_fee_max) ? el.open_integration_fee_max + ' €' : null,
              inc_fee: el.incoming_fee,
              outg_fee: el.outgoing_fee,
              onboarding_time: el.onboarding_time_text,
            })
          })

          // if (this.merchantAcc.sort.name) this.merchantAcc.sort.loading = false
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    createBankAccount() {
      this.$store.commit('setModal', {
        template: 'offer',
        options: {
          id: null,
          actionType: 'create',
          table: 'bank-offer',
          objectType: 'bank account',
          url: 'admin/offers',
          successfullMessage: 'You successfully created bank account',
        }
      })
    },
    createMerchantAccount() {
      this.$store.commit('setModal', {
        template: 'offer',
        options: {
          id: null,
          actionType: 'create',
          table: 'merchant-offer',
          objectType: 'merchant account',
          url: 'admin/offers',
          successfullMessage: 'You successfully created merchant account',
        }
      })
    },
    createEWallet() {
      this.$store.commit('setModal', {
        template: 'offer',
        options: {
          id: null,
          actionType: 'create',
          table: 'e-wallet',
          objectType: 'e-wallet',
          url: 'admin/offers',
          successfullMessage: 'You successfully created e-wallet',
        }
      })
    },
    editBankOffer(id) {
      this.$store.commit('setModal', {
        template: 'offer',
        options: {
          id: id,
          actionType: 'edit',
          objectType: 'bank account',
          url: 'admin/offers/update/' + id,
          successfullMessage: 'You successfully updated bank account',
        }
      })
    },
    deleteBankOffer(id) {
      this.$store.commit('setModal', {
        template: 'delete',
        options: {
          id: id,
          actionType: 'delete',
          table: 'bank-offer',
          type: 'offer',
          url: 'admin/offers/' + id,
          successfullMessage: 'You successfully deleted offer',
        }
      })
    },
    editMerchantOffer(id) {
      this.$store.commit('setModal', {
        template: 'offer',
        options: {
          id: id,
          actionType: 'edit',
          table: 'merchant-offer',
          objectType: 'merchant account',
          url: 'admin/offers/update/' + id,
          successfullMessage: 'You successfully updated merchant account',
        }
      })
    },
    deleteMerchantOffer(id) {
      this.$store.commit('setModal', {
        template: 'delete',
        options: {
          id: id,
          actionType: 'delete',
          table: 'merchant-offer',
          type: 'merchant',
          url: 'admin/offers/' + id,
          successfullMessage: 'You successfully deleted offer',
        }
      })
    },
    editEWallet(id) {
      this.$store.commit('setModal', {
        template: 'offer',
        options: {
          id: id,
          actionType: 'edit',
          table: 'e-wallet',
          objectType: 'e-wallet',
          url: 'admin/offers/update/' + id,
          successfullMessage: 'You successfully updated e-wallet',
        }
      })
    },
    deleteEWallet(id) {
      this.$store.commit('setModal', {
        template: 'delete',
        options: {
          id: id,
          actionType: 'delete',
          table: 'e-wallet',
          type: 'e-wallet',
          url: 'admin/offers/' + id,
          successfullMessage: 'You successfully deleted offer',
        }
      })
    },
    updateCurrentPage() {
      let currentPage = 1
      this.getBankOffers(currentPage)
      this.getMerchantOffers(currentPage)
      this.getEWallets(currentPage)
    },
    sortBy(data) {
      this[data.obj].sort.loading = true
      this[data.obj].sort.order = this[data.obj].sort.name === data.sort && this[data.obj].sort.order === 'asc'
        ? 'desc' : 'asc'
      this[data.obj].sort.name = data.sort

      switch (data.obj) {
        case 'bankAcc':
          this.getBankOffers(1)
          break;
        case 'merchantAcc':
          this.getMerchantOffers(1)
          break;
        case 'eWallets':
          this.getEWallets(1)
          break;
      }
    },
    downloadExcel(type) {
      axios.post('admin/offers/create-excel-report/' + type + '/' + this.showProvidersAccs)
        .then(function (response) {
          const url = response.data
          const link = document.createElement('a')
          link.href = url
          document.body.appendChild(link)
          link.click()
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    getRisks() {
      const that = this
      axios.get('admin/data/risks')
        .then(function (response) {
          response.data.sort((a, b) => a.order < b.order ? -1 : (a.order > b.order ? 1 : 0))
          that.filterRisks = response.data
          that.filterRisks.unshift({ id: null, name: 'Select risk' })
        })
        .catch(function (error) {
          console.log(error);
        })
    },
  },
  watch: {
    '$store.state.deleteItemFromTableData': function () {
      this.updateCurrentPage()
    },
    '$store.state.editItemFromTableData': function () {
      this.updateCurrentPage()
    },
    selectedRisk: function (selectedRisk) {
      this.getBankOffers()
      this.getMerchantOffers()
      this.getEWallets()
    }
  }
}
</script>

<style lang="scss" scoped>


.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: .25rem;
}

.page-link span {
  color: white;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-link {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  border: 1px solid #dee2e6;
  color: white !important;
}

.create-account-btn {
  margin-left: 30px;
}

.top-left-actions-btns {
  display: flex;
}

.select-risk {
  margin-left: 1rem;
  padding: 15px 40px;
  border-radius: 30px;
  background-color: var(--c_black);
  color: #fff;
}

.col-dark {
  color: #fff;
  display: flex;
  gap: 1rem;
}

.table-col-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>