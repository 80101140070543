<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img" />
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">
        {{ options.actionType | capitalize }} {{ options.objectType }}
      </div>
    </div>
    <div class="modal__body">
      <div class="form">
        <div class="col col-4">
          <div class="form-group">
            <label>
              <input type="checkbox" v-model="form.is_legal_company">
              Provided by Legal company
            </label>
          </div>
          <div class="form-group half">
            <label>Name of financial institution</label>
            <input v-model="form.financial_institution_name" type="text" class="form-control" placeholder="EMI">
            <span v-show="formErrors.financial_institution_name" class="invalid-feedback">{{
              formErrors.financial_institution_name }}</span>
          </div>
          <div class="form-group half">
            <label>Type of account</label>
            <input v-model="form.account_type" type="text" class="form-control" placeholder="SEPA">
            <span v-show="formErrors.account_type" class="invalid-feedback">{{ formErrors.account_type }}</span>
          </div>
          <div class="form-group">
            <label>
              <input type="checkbox" v-model="form.oif_min_max">
              Open/Integration fee min/max
            </label>
          </div>
          <div class="form-group half">
            <label>Open/Integration fee {{ form.oif_min_max ? 'MIN' : '' }}</label>
            <div class="inpt-block">
              <input v-model="form.open_integration_fee" type="text" class="form-control" placeholder="2.500.00">
              <div class="currency-symbol">€</div>
            </div>
            <span v-show="formErrors.open_integration_fee" class="invalid-feedback">{{ formErrors.open_integration_fee
            }}</span>
          </div>
          <div v-if="form.oif_min_max" class="form-group half">
            <label>Open/Integration fee MAX</label>
            <div class="inpt-block">
              <input v-model="form.open_integration_fee_max" type="text" class="form-control" placeholder="7.000.00">
              <div class="currency-symbol">€</div>
            </div>
            <span v-show="formErrors.open_integration_fee_max" class="invalid-feedback">{{
              formErrors.open_integration_fee_max }}</span>
          </div>
          <div class="form-group" :class="{ half: !form.oif_min_max }">
            <label>Onboarding time</label>
            <div class="inpt-block">
              <input v-model="form.onboarding_time" type="text" class="form-control" placeholder="3">
              <div class="desc">works day</div>
            </div>
            <span v-show="formErrors.onboarding_time" class="invalid-feedback">{{ formErrors.onboarding_time }}</span>
          </div>
          <div class="form-group">
            <label>Incoming fee</label>
            <input v-model="form.incoming_fee" type="text" class="form-control"
              placeholder="0.2%, min 10.00€, max 100.00€">
            <span v-show="formErrors.incoming_fee" class="invalid-feedback">{{ formErrors.incoming_fee }}</span>
          </div>
        </div>
        <div class="col col-4">
          <div class="form-group">
            <label>Outgoing fee</label>
            <input v-model="form.outgoing_fee" type="text" class="form-control"
              placeholder="0.5%, min 15.00€, max 350.00€">
            <span v-show="formErrors.outgoing_fee" class="invalid-feedback">{{ formErrors.outgoing_fee }}</span>
          </div>
          <div class="form-group">
            <label>Country</label>
            <div class="inpt-block">
              <select class="form-control" style="width: 100%;" v-model="form.country_id">
                <option value="null">Select country</option>
                <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
              </select>
            </div>
            <span v-show="formErrors.country_id" class="invalid-feedback">{{ formErrors.country_id }}</span>
          </div>
          <div class="form-group">
            <label>Description</label>
            <textarea class="form-control" v-model="form.description" rows="20"></textarea>
          </div>
          <div class="form-group file">
            <label>Logo</label>
            <div class="data">
              <input type="file" class="dn" ref="logo" accept="image/*" @change="handleLogoChange"/>
              <div v-if="form.logo" class="fileBox">
                <div class="filename">{{ form.logo.name }}</div>
                <div class="btn btn__remove" @click="removeLogoFile">&#10006;</div>
              </div>
              <div v-else class="fileBox">
                <div @click="$refs.logo.click()" class="btn btn__fileUpload">Upload file</div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Total score</label>
            <input class="form-control" type="number" min="0" max="5" step="0.1" v-model="form.score_value">
          </div>
          <div class="form-group">
            <label>Editor's review</label>
            <input v-model="form.score_review" type="text" class="form-control">
          </div>
          <div class="form-group pricing">
            <label>Pricing</label>
            <div class="form-group">
              <input v-model="services_pricing_text" placeholder="Text" type="text" class="form-control">
              <input style="margin-top: 10px" v-model="services_pricing_value" placeholder="Value" type="text"
                class="form-control">
            </div>
            <div class="pricing__list">
              <div class="pricing__item" v-for="item in form.services_pricing">{{ item.desc }}: {{ item.value }}</div>
            </div>
            <div class="btn pricing-btn-add" @click="addServicePrice">Add Service Price</div>
          </div>
          <div class="form-group pricing">
            <label>Additional Services</label>
            <div class="form-group">
              <input v-model="additional_service_text" placeholder="Additional service" type="text" class="form-control">
            </div>
            <div class="pricing__list">
              <div class="pricing__item" v-for="item in form.additional_services">{{ item.text }}</div>
            </div>
            <div class="btn pricing-btn-add" @click="addAdditionalService">Add additional service</div>
          </div>
          <SelectProvider :provider-id="form.provider_id" @selectProvider="selectProvider" />
        </div>
        <div class="col col-4">
          <div id="risks" class="form-group">
            <label>Risks</label>
            <div class="risks-items">
              <div
                v-if="risk.code != 'restricted' || options.actionType != 'edit' || (form.user_id == user.id || user.is_super_admin)"
                class="form-check risk-check" v-for="(risk, index) in risks" :key="index">
                <input v-model="form.risks" :value="risk.id" class="form-check-input" type="checkbox">
                <label>{{ (index + 1) }}. {{ risk.name }}</label>
                <!--  <a v-if="risk.industriesVisibility" class="select-industries" href="#" @click="risk.industriesVisibility = !risk.industriesVisibility">Hide industries</a>
                <a v-else class="select-industries" href="#" @click="risk.industriesVisibility = !risk.industriesVisibility">Show industries</a> -->
                <div v-if="risk.code != 'restricted'" v-show="risk.industriesVisibility">
                  <span class="industry-item" v-for="industry in risk.industries" :key="industry.id"><input
                      v-model="form.industries" :value="industry.id" type="checkbox">
                    {{ industry.name }}
                  </span>
                </div>
                <div class="restricted-input-wrapper" v-if="risk.code == 'restricted' && form.risks.includes(risk.id)">
                  <textarea v-model="form.restricted_countries" class="form-control restricted-input"
                    placeholder="Enter countries list" rows="5"></textarea>
                  <textarea v-model="form.restricted_business_types" class="form-control restricted-input"
                    placeholder="Enter business types" rows="5"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal__footer">
      <div class="btn" @click="submit">Save data</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Close from './../img/Close.vue'
import SelectProvider from './../provider/SelectProvider.vue'
import SelectProviderMixin from '@/mixins/selectProvider.js'

export default {
  mixins: [
    SelectProviderMixin
  ],
  components: {
    Close,
    SelectProvider,
  },
  props: {
    options: {
      type: Object,
      required: true,
    }
  },
  created() {
    this.getCountries()
    this.getIndustries()
  },
  data() {
    return {
      message: '',
      error: '',
      countries: [],
      industries: [],
      risks: [],
      form: {
        financial_institution_name: '',
        is_legal_company: false,
        account_type: '',
        oif_min_max: false,
        open_integration_fee: '',
        open_integration_fee_max: '',
        onboarding_time: '',
        incoming_fee: '',
        outgoing_fee: '',
        country_id: null,
        risks: [],
        industries: [],
        restricted_countries: '',
        restricted_business_types: '',
        logo: null,
        description: '',
        score_value: 0,
        score_review: '',
        services_pricing: [],
        additional_services: []
      },
      services_pricing_text: '',
      services_pricing_value: '',
      additional_service_text: '',
      formErrors: {
        financial_institution_name: '',
        account_type: '',
        open_integration_fee: '',
        open_integration_fee_max: '',
        onboarding_time: '',
        incoming_fee: '',
        outgoing_fee: '',
      },
      businessActivities: [],
    };
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
  },
  methods: {
    getCountries() {
      axios.get('admin/data/countries')
        .then(response => {
          this.countries = response.data
        })
        .catch(error => {
          console.log(error);
        })
    },
    clearErrors() {
      for (let key in this.form) {
        this.formErrors[key] = ''
      }
    },
    getIndustries() {
      axios.get('admin/data/industries')
        .then(response => {
          this.industries = response.data
          this.getRisks()
        })
        .catch(error => {
          console.log(error);
        })
    },
    getRisks() {
      axios.get('admin/data/risks')
        .then(response => {
          response.data.sort((a, b) => a.order < b.order ? -1 : (a.order > b.order ? 1 : 0))
          this.risks = response.data.map(risk => ({
            id: risk.id,
            industriesVisibility: false,
            name: risk.name,
            code: risk.code,
            industries: this.industries.map(industry => ({ id: risk.id + '-' + industry.id, name: industry.name }))
          }));
          if (this.options.actionType == 'edit') {
            this.getOffer(this.options.id)
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    getOffer() {
      axios.get('admin/offers/' + this.options.id, this.form)
        .then(response => {
          this.form = response.data
          this.form.user_id = this.user.id;

          const serverRisks = response.data.risks
          if (this.form.risks) {
            let arr = []
            this.form.industries = []
            for (let i = 0; i < this.form.risks.length; i++) {
              this.risks.forEach((risk, index) => {
                if (risk.id == serverRisks[i].id) {
                  this.risks[index].industriesVisibility = true
                }
              });
              if (this.form.risks[i].id) {
                arr.push(this.form.risks[i].id)
                this.form.risks[i].industries.forEach((industry, index) => {
                  this.form.industries.push(this.form.risks[i].id + '-' + industry.id)
                });
              }
            }
            this.form.risks = arr
            this.form.provider_id = null;
          }
        })
        .catch((errors) => { console.dir(errors) })
    },
    async submit() {
      this.clearErrors()

      if (this.options.actionType == 'create') {
        switch (this.options.objectType) {
          case 'bank account':
            this.form.type = 1;
            break;
          case 'merchant account':
            this.form.type = 2;
            break;
          case 'e-wallet':
            this.form.type = 3;
            break;
        }
      }

      console.log(this.form.services_pricing)

      if (this.form.services_pricing.length
        && this.form.services_pricing[this.form.services_pricing.length - 1].value.toString().trim() == ''
      ) {
        this.form.services_pricing.pop();
      }

      const formData = new FormData()

      for (const key in this.form) {
        if (Object.hasOwnProperty.call(this.form, key)) {
          const toJsonArr = ['risks', 'industries', 'country', 'services_pricing', 'additional_services'];
          const data = toJsonArr.includes(key) ? JSON.stringify(this.form[key]) : this.form[key];

          formData.append(key, data);
        }
      }

      axios.post(this.options.url, formData)
        .then(response => {
          console.log(response)
          if (response.data.errors) {
            const errors = response.data.errors
            for (let key in errors) {
              this.formErrors[key] = errors[key][0]
            }
          }
          if (response.data.success) {
            this.close()
            this.$noty.success(this.options.successfullMessage)
            this.$store.dispatch('editItemFromTable', {
              id: this.options.id,
              table: this.options.table,
              actionType: this.options.actionType
            })
          }
        })
        .catch(errors => console.dir(errors))
    },
    handleLogoChange(ev) {
      if (ev.target.files.length) {
        this.form.logo = ev.target.files[0]
      }
    },
    removeLogoFile() {
      this.$refs.logo.value = null
      this.form.logo = null
    },
    addServicePrice() {
      if (!this.form.services_pricing) return;
      this.form.services_pricing.push({ desc: this.services_pricing_text, value: this.services_pricing_value });
    },
    addAdditionalService() {
      this.form.additional_services.push({ text: this.additional_service_text});
    },
    close() {
      this.$emit("close");
    },
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  watch: {
    'form.risks': function (newRisks, oldRisks) {
      let newRisk = newRisks.filter(function (newRisk) {
        return !oldRisks.some(function (oldRisk) {
          return newRisk == oldRisk;
        });
      });
      if (newRisk[0]) {
        this.risks = this.risks.map(risk => ({
          id: risk.id,
          industriesVisibility: !risk.industriesVisibility ? (newRisk[0] == risk.id) ? true : false : risk.industriesVisibility,
          name: risk.name,
          code: risk.code,
          industries: risk.industries,
        }));
      } else {
        let oldRisk = oldRisks.filter(function (oldRisk) {
          return !newRisks.some(function (newRisk) {
            return oldRisk == newRisk;
          });
        });
        if (oldRisk[0]) {
          this.risks = this.risks.map(risk => ({
            id: risk.id,
            industriesVisibility: risk.industriesVisibility ? (oldRisk[0] == risk.id) ? false : true : risk.industriesVisibility,
            name: risk.name,
            code: risk.code,
            industries: risk.industries,
          }));
          const arr = []
          for (let i = 0; i < this.form.industries.length; i++) {
            if (!this.form.industries[i].startsWith(oldRisk[0] + '-')) {
              arr.push(this.form.industries[i])
            }
          }
          this.form.industries = arr
        }
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.modal {
  &__wrapper {
    width: 80%;
    max-width: 80%;
  }
}

#risks {
  display: flex;
  flex-direction: column;

  #risks>label {
    margin-bottom: 10px;
  }

  .risks-items {
    margin-top: 10px;
    margin-left: 24px;
  }

  .form-check-input[type=checkbox] {
    border-radius: .25em;
  }

  .form-check .form-check-input {
    float: left;
    margin-left: -1.5em;
  }

  .form-check label {
    margin-left: 5px;
  }

  .form-check-input {
    width: 1em;
    height: 1em;
    margin-top: .25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, .25);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }

  .form-check-input:checked {
    background-color: #1C31F0;
    border-color: #1C31F0;
  }
}

.form-control {
  margin-bottom: 0px !important;
}

.select-industries {
  margin-left: 10px;
  float: right;
  font-size: 10px;
}

.industry-item {
  margin: 0 5px;
  font-size: 12px;
}

.col-4 {
  width: 33.3%;
  padding: 0 1rem;
}

.risk-check {
  margin-bottom: 20px;
}

.restricted-input-wrapper {
  margin-top: 1rem;
}

.restricted-input {
  height: 7rem !important;
  margin: 0.25rem 0;
}

.file {
  .fileBox {
    display: flex;
    align-items: center;

    .filename {
      padding-right: .25rem;
    }

    .btn {
      display: inline-flex;
      line-height: 1;
      color: #fff;
      border-radius: .25rem;

      &__fileUpload {
        padding: 0.75rem 1rem 10px;
        text-transform: uppercase;
        background-color: var(--c_green);
      }

      &__remove{
        color: red;
      }
    }
  }
}

.pricing {
  flex-direction: column;
  align-items: flex-start;

  &-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    margin-bottom: .5rem;
    padding-bottom: .5rem;
    border-bottom: 1px solid grey;
  }

  &__list{
    margin-bottom: 20px;
  }

  &-btn-add {
    padding: .5rem 1rem;
    color: #fff;
    background-color: var(--c_green);
    border-radius: 0.25rem;
  }
}</style>
